<template>
  <v-row>
    <v-col cols="12">
      <h2 @click="playSound()">{{ $t("user.head") }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("user.head_list") }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input ref="startDate" :label="$t('user.register_date_from')" :date="startDate"
                @setValue="startDate = $event" />
            </span>
            <span>
              <date-picker-input ref="endDate" :label="$t('user.register_date_to')" :date="endDate"
                @setValue="endDate = $event" />
            </span>
            <!-- <span>
            <select-input label="สถานะ" />
          </span> -->

            <v-btn color="primary" @click="searchUser()"> {{ $t("search") }} </v-btn>
            <v-btn color="warning" @click="preloadExportCsv" class="">{{ $t("user.export_excel") }}</v-btn>
          </v-row>

          <v-row v-if="isCreateUser" no-gutters style="justify-content:end">
            <v-btn color="primary" @click="addNewUser">{{ $t("user.add_member") }}</v-btn>
          </v-row>
        </v-card-actions>
        <br />
        <v-card-actions>
          <v-row no-gutters>
            <v-card-title> {{ $t("user.member_list") }} {{ pagination.totalItems }} </v-card-title>
            <v-spacer />

            <span class="px-3 mt-5">{{ $t("search") }}: </span>
            <div style="width: 150px" class="mt-5">
              <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
            </div>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.currentPage" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="members">
            <template v-slot:[`item.bank`]="{ item }">
              <div v-if="!item.bankCountry">
                <img class="pt-3" v-if="item.bank" alt="bank_img" width="25px"
                  :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
              </div>
              <div v-else>
                <img class="pt-3" v-if="item.bank" alt="bank_img" width="25px"
                  :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.bank}.webp`" />
              </div>
              <span>{{ item.account }}</span>
            </template>
            <template v-slot:[`item.username`]="{ item }">
              <v-btn v-if="item.username === null" color="#e5e5e5" class="black--text" @click="createUserGame(item)">
                {{ $t("user.create") }}
              </v-btn>
              <v-btn v-else color="warning" @click="showMemberDetail(item)">
                {{ item.username }}
              </v-btn>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" v-on="on">
                    <v-icon small color="white">mdi-square-edit-outline</v-icon>
                    <span class="px-3 white--text">{{ $t("user.manage") }}</span>
                    <v-icon small color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title @click="linkToEditPage(item.id)">
                      <v-icon small color="warning">mdi-square-edit-outline</v-icon>
                      <span class="px-4">{{ $t("user.edit") }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider />
                  <v-list-item>
                    <v-list-item-title @click="deleteMember(item)">
                      <v-icon small color="error">mdi-delete</v-icon>
                      <span class="px-4">{{ $t("user.delete") }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
      <staff-show-detail :dialog="showDetail" :username="currentMemberDetail.username"
        :password="currentMemberDetail.password" :wallets="wallets" :deposit="depositwithdraw.deposit"
        :withdraw="depositwithdraw.withdraw" :depositWithdraw="depositwithdraw" @setDialog="showDetail = $event" />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import StaffShowDetail from '@/views/staff/StaffShowDetail.vue'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import store from '@/store'
import { addLogExportCSV } from '@/services/apis/logs'
export default {
  components: {
    DatePickerInput,
    SelectInput,
    StaffShowDetail,
  },

  data() {
    return {
      startDate: moment().subtract(3, 'days').format('YY-MM-DD'),
      endDate: moment().format('YY-MM-DD'),
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: this.$t("user.tel"),
          value: 'phone',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t("user.fullname"),
          value: 'name',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t("user.type"),
          value: 'type',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t("user.bank"),
          value: 'bank',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("user.register_date"),
          value: 'date',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t("user.user_pass"),
          value: 'username',
          align: 'center',
          sortable: false,
        }
      ],
      members: [],
      loading: false,
      dummy: true,
      canEdit: true,
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      wallets: [],
      depositwithdraw: { deposit: 0, withdraw: 0 },
      secureKey: null,
      isCreateUser: false,
      bankSystem: ''
    }
  },

  async created() {
    this.getWebSettingInfo()
    this.addLogPage()
    this.getPermissionsEdit()
    this.getCreateUser()
  },

  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) await this.searchPaginate(newValue.page, newValue.itemsPerPage)
      },
      deep: true,
    },
  },

  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'สมาชิก',
            url: window.location.href,
            detail: 'ระบบจัดการสมาชิก',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'user')
      if (chkEdit && chkEdit?.length > 0) {
        this.headers = [...this.headers,
        {
          text: this.$t("user.manage"),
          value: 'actions',
          align: 'center',
          sortable: false,
        }
        ]
      }
    },
    async getCreateUser() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'userAdd')
      if (chkEdit && chkEdit?.length > 0) {
        this.isCreateUser = true;
      }
    },
    async searchPaginate(page, row) {
      await this.getUsers(page, row, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
    },
    async searchUser() {
      this.dummy = false
      await this.getUsers(1, this.pagination.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      this.pagination.currentPage = 1
    },
    async getUsers(page, row, time_from, time_to, search) {
      this.loading = true
      this.members = []
      let rs = await this.$store.dispatch('getMember', {
        ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
        ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
        ...(search ? { search: search } : {}),
        page: page,
        rows: row,
        ...(!search ? { page: page } : {}),
        ...(!search ? { rows: row } : {}),
        // type: 'member',
      })
      this.pagination.totalItems = rs.count
      rs.data.forEach(element => {
        let splitCountry, splitBankCode;
        if (element?.bank_code && element?.country_code) {
          const code = element.bank_code;
          const checkCountryCode = (code) => {
          const validCodes = ['TH', 'KR', 'LA', 'TW', 'CN', 'KH', 'VN', 'MM'];
          const firstTwoChars = code.substring(0, 2);
            return validCodes.includes(firstTwoChars);
          }

          if (checkCountryCode(code)) {
            splitCountry = code.slice(0, 2);
            splitBankCode = code.slice(2);
          } else {
            splitCountry = this.bankSystem;
            splitBankCode = code;
          }

          element.bankCountry = splitCountry.toLowerCase();
          element.bank = splitBankCode;
        }

        this.members.push({
          id: element.id,
          name: element.name ? element.name : '',
          email: element.email ? element.email : '',
          line: element.line ? element.line : '',
          phone: element.phone ? element.phone : '',
          bankCountry: element.bankCountry,
          bank: element.bank,
          username: element.username,
          account: element.bank_account,
          type: element.type === 'partner' ? 'marketing' : 'member',
          // date: '',
          wallet_auto: '0.00',
          wallet_commission: '0.00',
          username: element.member_game ? element.member_game.username : null,
          password: '',
          date: moment(element.created_at).format('YY-MM-DD HH:mm'),
        })
      })
      this.loading = false
    },
    async search() {
      this.dummy = false
      await this.getUsers(this.pagination.page, this.pagination.itemsPerPage, this.dateFrom, this.dateTo)
      this.pagination.currentPage = 1
    },
    async showMemberDetail(value) {
      this.currentMemberDetail = value
      let rs = await this.$store.dispatch('getMemberWallet', value.id)
      this.wallets = rs

      let rs2 = await this.$store.dispatch('getMemberDepositWithdraw', value.id)
      this.depositwithdraw = rs2

      this.showDetail = true
    },
    async createUserGame(value) {
      try {
        this.$swal
          .fire({
            icon: 'warning',
            title: this.$t("alert"),
            text: `${this.$t("user.create_title")} ${value.phone}`,
            showCancelButton: true,
            confirmButtonText: this.$t("confirm"),
            cancelButtonText: this.$t("cancel"),
          })
          .then(async result => {
            /* Read more about isConfirmed, isDenied below */
            this.$swal.fire({
              html: this.$t("processing"),
              timerProgressBar: true,
              didOpen: () => { this.$swal.showLoading() }
            })
            if (result.isConfirmed) {
              try {
                await this.$store.dispatch('createGame', value.id)
                this.$swal.close()
                this.$swal.fire(this.$t("user.create_completed"), '', 'success')
              } catch (e) {
                if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
                  this.$swal.close()
                  this.$swal.fire({
                    icon: 'error',
                    title: e.err,
                  })
                }
              }
              await this.getUsers(this.pagination.page, this.pagination.itemsPerPage)
              //  this.pagination.totalItems = result.count
              // this.value = result.data
              this.loading = false
            }
          })
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.err,
          })
        }
      }
    },
    linkToEditPage(id) {
      this.$router.push({ name: 'user-form', params: { id } })
    },
    async searchKeyword(value) {
      this.dummy = true;
      this.searchData = value
      await this.getUsers(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.currentPage = 1
    },
    async deleteMember(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t("processing"),
          text: `${this.$t("user.confirm_delete")} ${item.username}`,
          showCancelButton: true,
          confirmButtonText: this.$t("confirm"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(async result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await this.$store.dispatch('deleteMember', item.id)
            this.$swal.fire(this.$t("user.confirm_delete"), '', 'success')
            await this.getUsers(this.pagination.page, this.pagination.itemsPerPage)
            //  this.pagination.totalItems = result.count
            // this.value = result.data
            this.loading = false
          }
        })
    },

    async preloadExportCsv() {
      //////// get logExportData ////////
      let logExportData;
      const userSystem = store.getters.getuserInfo
      await fetch("https://jsonip.com/").then(res => res.json().then(el => (
        logExportData = {
          ip: el.ip,
          exportPage: 'สมาชิก - ระบบจัดการสมาชิก',
          url: window.location.href,
          detail: 'ระบบจัดการสมาชิก',
          admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
        }
      )))

      this.$swal({
        title: `Secure code | รายงาน${logExportData.exportPage}`,
        input: 'password',
        // inputAttributes: {
        //   inputmode: 'numeric',
        //   pattern: '[0-9]*',
        //   maxlength: 6,
        // },
        inputValidator: value => {
          if (!value) {
            return 'โปรดกรอก Secure code'
          }
          // if (value && value.length !== 6) {
          //   return 'โปรดกรอกให้ครบ 6 หลัก'
          // }
        },
        inputPlaceholder: 'ใส่รหัสที่นี่...',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ออก',
      }).then(async result => {
        if (result.isConfirmed) {
          const pin = result.value
          let enterSecureData = {
            ...logExportData,
            keyCheck: pin
          }
          this.loading = true
          let resEnterSecureData = await this.$store.dispatch('checkLogEnterSecure', enterSecureData)
          if (resEnterSecureData && resEnterSecureData.secure) {
            this.exportCsv()
          } else {
            this.$swal.fire('ไม่สามารถ download ได้ เนื่องจากรหัส PIN ไม่ถูกต้อง', '', 'error')
          }
          this.loading = false
        }
      })
    },
    async exportCsv() {
      this.$swal.fire({
        html: this.$t("processing"),
        timerProgressBar: true,
        didOpen: () => { this.$swal.showLoading() }
      })

      let fileName = `${this.$t("user.file_name")}_${moment(new Date()).format('YYYY-MM-DD_HH-mm')}`
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const wscols = [
        { wch: 15 },
        { wch: 25 },
        { wch: 10 },
        { wch: 10 },
        { wch: 20 },
        { wch: 20 },
        { wch: 15 },
      ];
      let statementlist = []
      let rs = await this.$store.dispatch('getMember', {
        ...(this.$refs.startDate.value ? { time_from: this.formatDateYYYY(this.$refs.startDate.value) } : {}),
        ...(this.$refs.endDate.value ? { time_to: this.formatDateYYYY(this.$refs.endDate.value) } : {}),
        ...(this.searchData ? { search: this.searchData } : {}),
        page: 1,
        rows: 1000000,
        // type: 'member',
      })
      // console.log(rs.data);
      rs.data.forEach(element => {
        statementlist.push({
          phone: element.phone,
          name: element.name,
          type: element.type,
          bank: element.bank,
          bank_account: element.bank_account,
          date: element.created_at ? moment(element.created_at).format('YYYY-MM-DD HH:mm') : '',
          username: element.member_game ? element.member_game.username : '',
        })
      })
      let ws = XLSX.utils.json_to_sheet(statementlist);
      ws['!cols'] = wscols;
      const wb = { Sheets: { UsersReport: ws }, SheetNames: ["UsersReport"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      // add log admin export
      await this.addLogExportCSV()
      this.$swal.close()
    },
    async addLogExportCSV() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            exportPage: 'สมาชิก',
            url: window.location.href,
            detail: 'ระบบจัดการสมาชิก',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogExportCSV', data)
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },
    playSound() {
      this.$store.dispatch('setPlaySound', true)
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
    addNewUser() {
      this.$router.push({ name: 'user-form-add' })
    },
  },
}
</script>
<style scoped>
@media (max-width: 550px) {
  .spacer {
    width: 100%;
  }
}
</style>
